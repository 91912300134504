import { TbMathFunction } from 'react-icons/tb'
import { Stack } from '~/components-v2'

const FormulaHighlight = () => (
  <div className="formula">
    <span>=</span>(<span className="cell1">B1</span> -{' '}
    <span className="cell2">B8</span>)<span>/</span>
    <span className="cell3">B8</span>
  </div>
)

const rowData = [
  ['2024-10-14', '401', <FormulaHighlight key="formula" />],
  ['2024-10-13', '357', '7.53%'],
  ['2024-10-12', '347', '-0.57%'],
  ['2024-10-11', '252', '1.25%'],
  ['2024-10-10', '282', '5.92%'],
  ['2024-10-09', '419', '-1.43%'],
  ['2024-10-08', '406', '8.94%'],
  ['2024-10-07', '389', '2.15%'],
  ['2024-10-06', '332', '-7.12%'],
  ['2024-10-05', '349', '0.92%'],
]

export const SheetGridPreview = () => (
  <div className="sheet-preview">
    <Stack className="function-bar" row align="center" gap={2}>
      <TbMathFunction />
      <FormulaHighlight />
    </Stack>
    <div className="sheet">
      <div className="header sheet-row">
        <div className="cell header-cell"></div>
        <div className="cell header-cell">A</div>
        <div className="cell header-cell">B</div>
        <div className="cell header-cell">C</div>
      </div>
      <div className="sheet-row">
        <div className="cell view-cell"></div>
        <div className="cell view-cell">
          <Stack className="column-name">
            <span className="view">Orders</span>
            <span className="label">Created Date</span>
          </Stack>
        </div>

        <div className="cell view-cell">
          <Stack className="column-name column-measure">
            <span className="view">Orders</span>
            <span className="label">Orders Count</span>
          </Stack>
        </div>
        <div className="cell view-cell">
          <Stack className="column-name column-calc">
            <span className="view">
              <TbMathFunction />
            </span>
            <span className="label">% Change</span>
          </Stack>
        </div>
      </div>
      {rowData.map((row, rowIndex) => (
        <div className="sheet-row" key={`row-${rowIndex}`}>
          <div className={`cell cell-${rowIndex + 1}-0}`}>{rowIndex + 1}</div>
          {row.map((value, cellIndex) => (
            <div
              className={`cell body-cell cell-${rowIndex + 1}-${cellIndex + 1}`}
              key={`${rowIndex}-${cellIndex}`}
            >
              {value}
            </div>
          ))}
        </div>
      ))}
    </div>
  </div>
)
